import { MenuItem } from 'primeng/api';
import { LocalStorageKey } from '../../constants';
import { FilterOption } from './components/filter-tag/filter-tag.component';

export class DatatableColumnV3 {
  name: string;

  cellTemplate: CellTemplates;

  filter?: boolean;

  selected: boolean;

  prop: string;

  filterProp?: string;

  frozen?: boolean;

  link?: boolean;

  filterCount? = 0;

  captionText?: string;

  tooltipText?: string;

  tooltipTextIconPosition?: IconPosition;

  tooltipTextIconSize?: ButtonSize;

  hide?: boolean;

  actionType?: TableActionTypes;

  pendoTag?: string;

  items?: SettingDropdownItems[];

  showImage?: boolean;

  translatePrefix?: string;

  filterValues?: any[];

  lazy?: boolean;

  operator?: ComparisonOperator | ComparisonOperatorsV2;

  dateType?: DateTypes;

  matchMode?: MatchModes;

  showOnMobile?: boolean;

  allItemsLoaded?: boolean;

  disableValueTranslation?: boolean;

  showMetadata?: boolean;

  metadataIdentifier?: MetadataIdentifiers;

  onSearch?: (
    col: DatatableColumnV3,
    value: string,
    limit: number,
    offset: number,
  ) => void;
}

export enum ComparisonOperator {
  Equal = '=',
  LessThan = '<',
  GreaterThan = '>',
  LessThanOrEqual = '<=',
  GreaterThanOrEqual = '>=',
}

export enum ComparisonOperatorsV2 {
  Equal = '=',
  LessThan = '<',
  GreaterThan = '>',
  Between = 'between',
}

export interface ComparisonOperatorOption {
  name: string;
  value: ComparisonOperator | ComparisonOperatorsV2;
}

export enum MatchModes {
  Equal = 'equal',
  Like = 'like',
  In = 'in',
  Between = 'between',
  MoreThan = 'moreThan',
  LessThan = 'lessThan',
  MoreThanOrEqual = 'MoreThanOrEqual',
  LessThanOrEqual = 'LessThanOrEqual',
  Not = 'not',
  Is = 'is',
}

export enum ToolTipPosition {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
}

export interface SettingDropdownItems {
  id?: string;
  name: string;
  actionBtnName?: string | ((item) => string);
  iconClass: string;
  handler?: (any) => void;
  disabled?: boolean | ((item) => boolean);
  hidden?: (rowData) => boolean;
  children?: (rowData) => SettingDropdownItems[];
  permission?: string;
  displayName?: boolean | ((item?) => boolean);
  tooltip?: string;
  tooltipPosition?: ToolTipPosition;
  tracker?: string;
  styleClass?: string;
  itemType?: ItemType;
}

export enum MetadataIdentifiers {
  ProductMovementReport = 'ProductMovementReport',
}

export interface DatatableV3 {
  search?: TableHeadSearch;
  globalFilter?: TableHeadFilter;
  primaryButton?: TableHeadButton;
  secondaryButton?: TableHeadButton;
  secondarySplitButton?: TableHeadSplitButton;
  extraSplitButton?: TableHeadSplitButton;
  sortDropdown?: TableHeadSortDropdown;
  leftFrozenWidth?: number;
  rightFrozenWidth?: number;
  numberOfResults?: number;
  showCheckboxes?: boolean;
  showFilterTags?: boolean;
  resizeColumn?: boolean;
  showCurrentPageReport?: boolean;
  clearButton?: TableHeadButton;
  trackByIdentifier?: string;
  reportTitle?: string;
  showSummarySection?: boolean;
  fromDate?: Date;
  toDate?: Date;
}

export type DatatableFilterEvent = {
  filters: { [key: string]: Filter[] };
  first: number;
  rows: number;
  sortingInfo: SortingInfo;
  searchText?: string;
  onlyPageChange: boolean;
};

export type Filter = {
  key?: string;
  value: string | string[];
  matchMode: MatchModes;
  operator: string;
};

export interface SortingInfo {
  key: string;
  value: string;
}

export interface TableHeadSearch {
  label: string;
  placeholder: string;
  icon: string;
  type: InputTypes;
  hint: string;
  tracker: string;
}

export interface TableHeadButton {
  label: string;
  type: ButtonTypes;
  icon: string;
  size: ButtonSize;
  badge?: number;
  permission?: string;
  tracker?: string;
  disabled?: boolean;
  loading?: boolean;
  rounded?: boolean;
  responsive?: boolean;
  tooltipText?: string;
  tooltipPosition?: ToolTipPosition;
  onClick?: (event?: unknown) => void;
}

export interface TableHeadFilter extends TableHeadButton {
  filteredColumns?: DatatableColumnV3[];
  appliedFilters?: any[];
  dateRanges?: unknown[];
  autoCompleteSearches?: string[] | unknown;
  autoCompleteInputs?: object;
}

export interface TableHeadSplitButton extends TableHeadButton {
  iconPosition?: IconPosition;
  showDropdownIcon?: boolean;
  options?: MenuItem[];
}

export interface TableFilter {
  label: string;
  value: number | boolean | string;
  key: string;
}

export interface TableHeadSortDropdown {
  icon: string;
  options: { value: string; name: string; key: string; selected?: boolean }[];
}

export interface TableHeadFilterTags {
  options: FilterOption[];
}

export interface DataTableValue {
  [propName: string]: any;
}

export interface PageOptions {
  first: number;
  page: number;
  pageCount: number;
  rows: number;
}

export interface TimeOptions {
  label: string;
  value: TimeTypes;
}

export enum TimeTypes {
  AM = 'AM',
  PM = 'PM',
}

export enum DateTypes {
  Specific = 'specific',
  Range = 'range',
}

export enum InputTypes {
  Default = 'default',
  Float = 'float',
  Compact = 'compact',
  CompactSmall = 'compact-small',
}

export enum ButtonTypes {
  Primary = 'primary',
  PrimaryLight = 'primary-light',
  PrimaryTransparent = 'primary-transparent',
  PrimaryOutline = 'primary-outline',
  PrimaryLightOutline = 'primary-light-outline',
  Danger = 'danger',
  DangerOutline = 'danger-outline',
  DangerTransparent = 'danger-transparent',
  GreyTransparent = 'grey-transparent',
  PurpleWhite = 'purple-white',
}

export enum ButtonSize {
  ExtraExtraSmall = 'xss',
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}

export enum IconPosition {
  Left = 'left',
  Right = 'right',
}

export enum TableActionTypes {
  ClearAll = 'clearAll',
  Customize = 'customize',
  Button = 'button',
}

export enum ItemType {
  Action = 'action',
  Button = 'button',
}

export enum CellTemplates {
  Text = 'text',
  DropDown = 'dropdown',
  MultiSelect = 'multiselect',
  AutoComplete = 'autocomplete',
  Numeric = 'numeric',
  NumericV2 = 'numeric-v2',
  Date = 'date',
  DateTime = 'datetime',
  Boolean = 'boolean',
  BooleanNegate = 'booleanNegate',
  Default = 'default',
  None = 'None',
  InnerHtml = 'innerHtml',
}

export interface FilterMultiSelectOption {
  name: string;
  value: string;
}

export enum FilterPreservationComponentKey {
  SalesByLocation = 'sales-by-location',
}

export enum IdentifierKey {
  Expenses = 'expenses',
  OrderListV2 = 'orderListV2',
}

export const DEFAULT_RECORD_COUNTS = [10, 25, 50, 100, 200];

export const DEFAULT_MATCH_MODES = {
  [CellTemplates.MultiSelect]: MatchModes.In,
  [CellTemplates.Text]: MatchModes.Like,
  [CellTemplates.Date]: MatchModes.Between,
  [CellTemplates.DateTime]: MatchModes.Between,
  [CellTemplates.DropDown]: MatchModes.In,
  [CellTemplates.Boolean]: MatchModes.Is,
  [CellTemplates.BooleanNegate]: MatchModes.Is,
  [CellTemplates.Numeric]: MatchModes.Equal,
  [CellTemplates.AutoComplete]: MatchModes.In,
};

export const MATCH_MODE_BY_OPERATOR = {
  [ComparisonOperator.Equal]: MatchModes.Equal,
  [ComparisonOperator.LessThan]: MatchModes.LessThan,
  [ComparisonOperator.GreaterThan]: MatchModes.MoreThan,
  [ComparisonOperator.GreaterThanOrEqual]: MatchModes.MoreThanOrEqual,
  [ComparisonOperator.LessThanOrEqual]: MatchModes.LessThanOrEqual,
};

export const CUSTOM_PREFERENCE_KEY = {
  [IdentifierKey.Expenses]: LocalStorageKey.ExpenseColumnCustomization,
  [IdentifierKey.OrderListV2]: LocalStorageKey.OrderListV2ColumnCustomization,
};

export const CUSTOM_DATE_QUERY_PARAM_FIELD = 'custom_date';
